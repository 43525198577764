import React from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from './components/Header';
import {Navigation, useNavbarHeight} from './components/Navigation';
import Home from './components/Home';
import Services from './components/Services';
import Precios from './components/Precios';
import Equipo from './components/Equipo';
import Facilities from './components/Facilities';
import Contact from './components/Contact';

function App() {
	
  return (
    <div>
      <Navigation />
      <Home />
      <Services />
      <Precios />
      {/* <Equipo /> */}
      {/* <Facilities /> */}
      <Contact />
    </div>
  );
}

export default App;