import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import cafeteria from './cafeteria.jpg';
import terraza from './terraza.jpg';
import salaEntreno2 from './sala-entreno2.png';
import vestuario2 from './vestuario2.jpg';
import despacho from './despacho-dietista-nutricionista.jpg';
import fisio from './fisio.png';
import {useNavbarHeight} from './Navigation';

function Facilities() {
  return (
    <section id="facilities" style={{paddingTop:`${useNavbarHeight()}px`}}>
      <h2>Nuestras Instalaciones</h2>
      <Row>
        <Col md={4} className="mb-4">
          <Image src={cafeteria} fluid />
          <p>Bar/Cafetería para el post entreno</p>
        </Col>
        <Col md={4} className="mb-4">
          <Image src={terraza} fluid />
          <p>Terraza y zona chill-out</p>
        </Col>
        <Col md={4} className="mb-4">
          <Image src={salaEntreno2} fluid />
          <p>Instalaciones deportivas</p>
        </Col>
        <Col md={4} className="mb-4">
          <Image src={vestuario2} fluid />
          <p>Vestuarios</p>
        </Col>
        <Col md={4} className="mb-4">
          <Image src={despacho} fluid />
          <p>Despacho para atención personalizada</p>
        </Col>
        <Col md={4} className="mb-4">
          <Image src={fisio} fluid />
          <p>Sala de recuperación muscular y fisioterapia</p>
        </Col>
      </Row>
    </section>
  );
}

export default Facilities;