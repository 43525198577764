import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import {useNavbarHeight} from './Navigation';
import MapComponent from './MapComponent';

function Contact() {
  return (
    <section id="contact" style={{paddingTop:`${useNavbarHeight()}px`}}>
	    {/* <MapComponent /> */}
      <h2>Contacto</h2>
      <Row>
        <Col md={3} className="infoForm">
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text" placeholder="Ingrese su nombre" />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Ingrese su email" />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label>Mensaje</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>
            <Button variant="primary" type="submit">
              Enviar
            </Button>
          </Form>
        </Col>
        <Col md={7} className="infoContacto">
          {/* <h3>Información de Contacto</h3> */}
          <Row style={{height: '100%'}}>
            <Col md={6} className="infoContactoMap">
              <MapComponent />
            </Col>
            <Col md={6} className="infoContactoDatos">
              {/* <div className="infoContactoDatos"> */}
                <Row>
                  <Col>
                    <p>Dirección:</p>
                  </Col>
                  <Col>
                    <p>Carrer Altarriba i Godolà, 13, 08600 Berga, Barcelona</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Horario:</p>
                  </Col>
                  <Col>
                    <p>Lunes a Viernes de 7:00 AM a 11:00 PM</p>
                    <p>Sábados, Domingos y Festivos de 9:00 AM a 7:00 PM</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Teléfono:</p>
                  </Col>
                  <Col> 
                    <p>(+34) 680367613</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Email:</p>
                  </Col>
                  <Col>
                    <p>info@ceec.com</p>
                  </Col>
                </Row>
              {/* </div> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
}

export default Contact;