import React, {useState, useEffect} from 'react';
import { Navbar, Nav, Image } from 'react-bootstrap';

export function useNavbarHeight() {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    // Seleccionamos la imagen del logo
    const logoImage = document.querySelector('.navbar-brand img');
    const navbar = document.querySelector('.navbar');

    // Función para actualizar la altura
    const updateHeight = () => {
      if (navbar) {
        // Usamos la altura del navbar (que incluye logo)
        setHeight(navbar.offsetHeight);
      }
    };

    // Comprobamos si la imagen ya está completamente cargada
    if (logoImage) {
      // Si la imagen ya está cargada, medimos la altura inmediatamente
      if (logoImage.complete) {
        updateHeight();
      } else {
        // Si no está cargada, esperamos el evento load
        logoImage.addEventListener('load', updateHeight);
      }
    }

    // Limpiar el event listener cuando el componente se desmonte
    return () => {
      if (logoImage) {
        logoImage.removeEventListener('load', updateHeight);
      }
    };
  }, []); // Solo se ejecuta cuando el componente se monta


  return height;
}

export function Navigation() {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" fixed="top" style={{paddingLeft: '1rem'}}>
      <Navbar.Brand href="#home"><Image src='images/ceecLogo.png' /></Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="#home">Inicio</Nav.Link>
          <Nav.Link href="#services">Servicios</Nav.Link>
          {/* <Nav.Link href="#equipo">Equipo</Nav.Link> */}
          {/* <Nav.Link href="#facilities">Instalaciones</Nav.Link> */}
          <Nav.Link href="#contact">Contacto</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

/*export default Navigation;*/