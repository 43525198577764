import {Row, Col} from 'react-bootstrap'
import {useNavbarHeight} from './Navigation';

function Precios() {
    return (
        <section id="precios" style={{paddingTop:`${useNavbarHeight()}px`}}>
            <h2>Elige el mejor plan</h2>
            <Row style={{justifyContent: 'center', columnGap: '10px'}}>
                <Col md={2} style={{padding: '15px', border: 'solid black 1px', borderRadius: '10px', textAlign: 'center'}}>
                    <div>
                        <span style={{fontSize: '55px'}}>30</span><span>€/mes</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16">
                            <path fill="#00b090" d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z" />
                        </svg>
                        <span>Acceso de Lunes a Viernes</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16">
                            <path fill="#00b090" d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z" />
                        </svg>
                        <span>Limite de acceso por franja horaria</span>
                    </div>
                </Col>
                <Col md={2} style={{padding: '15px', border: 'solid black 1px', borderRadius: '10px', textAlign: 'center'}}>
                    <div>
                        <span style={{fontSize: '55px'}}>45</span><span>€/mes</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16">
                            <path fill="#00b090" d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z" />
                        </svg>
                        <span>Acceso de Lunes a Viernes</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16">
                            <path fill="#00b090" d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z" />
                        </svg>
                        <span>Sin limite horario de acceso</span>
                    </div>
                </Col>
                <Col md={2} style={{padding: '15px', border: 'solid black 1px', borderRadius: '10px', textAlign: 'center'}}>
                    <div>
                        <span style={{fontSize: '55px'}}>60</span><span>€/mes</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16">
                            <path fill="#00b090" d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z" />
                        </svg>
                        <span>Acceso de Lunes a Domingo y Festivos</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16">
                            <path fill="#00b090" d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z" />
                        </svg>
                        <span>Sin limite horario de acceso</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16">
                            <path fill="#00b090" d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z" />
                        </svg>
                        <span>Invitacion mensual para acompañante</span>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16">
                            <path fill="#00b090" d="M12 8.758a.694.694 0 0 1-.217.515l-5.605 5.485-1.053 1.03A.725.725 0 0 1 4.6 16a.724.724 0 0 1-.527-.212l-1.053-1.03-2.803-2.743A.694.694 0 0 1 0 11.5c0-.202.072-.374.217-.515l1.053-1.03a.725.725 0 0 1 .526-.213c.207 0 .382.071.527.213L4.6 12.19l5.078-4.977c.14-.14.33-.216.527-.212.206 0 .382.07.526.212l1.053 1.03c.142.134.22.321.217.516Z" />
                        </svg>
                        <span>Acceso aplicacion de entrenamientos</span>
                    </div>
                </Col>
            </Row>
        </section>
    );
}

export default Precios;