import React from 'react';
import { Carousel, Row, Col, Image, Button } from 'react-bootstrap';
import squad from './squad.jpg';
import salaEntreno from './sala-entreno.jpg';
import fisio from './fisio.png';
import vestuario from './vestuario.png';
import services from './services.jpg';
import contact from './contact.jpg';
import {useNavbarHeight} from './Navigation';

function Home() {
  return (
    <section id="home" style={{paddingTop:`${useNavbarHeight()}px`}}>
      {/* <h1 style={{fontSize:120, textAlign:'center'}}>Gym</h1> */}
      {/* <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={squad}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={salaEntreno}
            alt="Second slide"
          />
        </Carousel.Item>
		    <Carousel.Item>
          <img
            className="d-block w-100"
            src={fisio}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={vestuario}
            alt="Fourth slide"
          />
        </Carousel.Item>
      </Carousel> */}

      
      <div className="banner">
        {/* Imagen de fondo */}
        <Image src="images/gymHome.jpg" className="bannerImage" />
        {/* <img 
          src="ruta_de_tu_imagen.jpg" 
          alt="Banner" 
          style={{
            width: '100%', 
            height: '100%', 
            objectFit: 'cover',
          }} 
        /> */}
        {/* Div superpuesto */}
        
        <div className="bannerOverlay">
          {/* <div style={{display: 'grid'}}> */}
          <div style={{display: 'grid', paddingLeft: '20%'}}>
            <div className="bannerOverlayLogo">
              <Image src='images/ceecLogo.png' />
            </div>
            <div style={{display: 'grid', alignSelf: 'start', color: 'red'}}>
              <span style={{fontSize: '70px'}}>No esperes mas</span><span>para ponerte en forma</span>
            </div>
            <div>
              <Button type="button" href="#contact" style={{color: 'black', backgroundColor: 'yellow', borderColor: 'yellow'}}>Apuntate</Button>
            </div>
          </div>
        </div>
      </div>
      
      <Row className="homeInfo">
        <Col md={2} className="text-center">
          <Image src={services} roundedCircle />
          <p>Servicios de calidad</p>
        </Col>
        <Col md={2} className="text-center">
          <Image src={services} roundedCircle />
          <p>Instalaciones modernas</p>
        </Col>
        <Col md={2} className="text-center">
          <Image src={services} roundedCircle />
          <p>Atención personalizada</p>
        </Col>
		    {/* <Col md={2} className="text-center">
          <Image src={contact} roundedCircle />
          <p>Donde estamos</p>
        </Col> */}
      </Row>
    </section>
  );
}

export default Home;