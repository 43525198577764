import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

// Asegúrate de importar los estilos de Leaflet para que los mapas se muestren correctamente
import 'leaflet/dist/leaflet.css';

// Corregir el icono predeterminado
delete L.Icon.Default.prototype._getIconUrl;

// Configuración de las rutas de los iconos de Leaflet
L.Icon.Default.mergeOptions({
  iconUrl: require('leaflet/dist/images/marker-icon.png'), // Ruta correcta
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),  // Ruta retina
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')  // Sombra del marcador
});

const MapComponent = () => {
  // Estado para la ubicación (puedes usar una ubicación estática o dinámica)
  const [position, setPosition] = useState([42.102109044189376, 1.8488133200659533]); // Coordenadas de ejemplo: latitud y longitud

  // Opcional: si quieres obtener la ubicación del usuario (geolocalización)
  /*useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setPosition([position.coords.latitude, position.coords.longitude]);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, []);*/

  return (
    // <div style={{ height: '500px' }}>
      <MapContainer center={position} zoom={15} style={{ width: '100%', height: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position}>
          <Popup>
            Estás aquí
          </Popup>
        </Marker>
      </MapContainer>
    // </div>
  );
};

export default MapComponent;