import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import training from './training.jpg';
import clase from './clase.jpg';
import nutricion from './nutricion.jpg';
import {useNavbarHeight} from './Navigation';

function Services() {
  return (
    <section id="services" style={{paddingTop:`${useNavbarHeight()}px`}}>
      <h2>Nuestros Servicios</h2>
      <Row>
        <Col md={4} className="servicesDescription">
          <h3>Entrenamientos Personales</h3>
          <p>Descripción detallada del servicio de entrenamientos personales...</p>
        </Col>
        <Col md={4} className="servicesImage">
          <Image src={training} fluid />
        </Col>
      </Row>
      <Row>
        <Col md={4} className="servicesImage">
          <Image src={nutricion} fluid />
        </Col>
        <Col md={4} className="servicesDescription">
          <h3>Preparación Nutricional</h3>
          <p>Descripción detallada del servicio de preparación nutricional...</p>
        </Col>
      </Row>
      <Row>
        <Col md={4} className="servicesDescription">
          <h3>Clases dirigidas</h3>
          <p>Descripción detallada del servicio de clases dirigidas...</p>
        </Col>
        <Col md={4} className="servicesImage">
          <Image src={clase} fluid />
        </Col>
      </Row>
    </section>
  );
}

export default Services;